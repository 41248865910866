const cards_data = [
    {
        id:"1", 
        imagePath: "56.jpg", 
        projectName: "Nakaloke Borehole",
        tag: "Waterwell Projects",
        projectDescription:"The Nakaloke Borehole project aims to provide sustainable access to clean and safe drinking water for the community of Nakaloke. Situated in a region where water scarcity is a pressing issue, this initiative strives to make a positive impact on the lives of the local residents.  The ultimate goal is to improve the overall well-being of the community, promote hygiene, and contribute to the development of a healthier and more resilient Nakaloke.",
        funded:"$500",
        remaining:"$400",
        target:"$900",
    },
    {
        id:"2", 
        imagePath:"46.jpg", 
        projectName: "Kanginima Borehole",
        projectDescription:"The Kanginima Borehole project aims to provide sustainable access to clean and safe drinking water for the community of Kanginima. Situated in a region facing water scarcity challenges, this initiative strives to make a positive impact on the lives of local residents. The primary goal is to improve overall well-being, promote hygiene, and contribute to the development of a healthier and more resilient Kanginima.",
        tag: "Waterwell Projects",
        funded:"$350",
        remaining:"$600",
        target:"$950",
    },
    {
        id:"3", 
        imagePath:"65.jpeg", 
        projectName: "Kinyoli Borehole",
        projectDescription:"The Kinyoli Borehole project endeavors to establish sustainable water access in the community of Kinyoli. This initiative is driven by the commitment to enhance the well-being of the local residents by addressing water scarcity issues. The project contributes to improved hygiene practices and the overall resilience of Kinyoli.",
        tag: "Waterwell Projects",
        funded:"$100",
        remaining:"$900",
        target:"$1000",
    },
    {
      id:"4", 
      imagePath:"64.jpeg", 
      projectName: "Bilal Waterwell",
      projectDescription:"The Bilal Waterwell project focuses on providing sustainable access to clean and safe drinking water for the community. Situated in an area with water scarcity concerns, the initiative aims to positively impact the lives of local residents by improving overall well-being, promoting hygiene, and contributing to community resilience.",
      tag: "Waterwell Projects",
      funded:"$310",
      remaining:"$590",
      target:"$900",
  },
  {
    id:"5", 
    imagePath:"63.jpeg", 
    projectName: "Bududa Borehole Proj",
    tag: "Waterwell Projects",
    funded:"$250",
    remaining:"$800",
    target:"$1050"
  },
  {
    id:"6", 
    imagePath:"55.jpg", 
    projectName: "Kakoro Borehole Construction",
    tag: "Waterwell Projects",
    funded:"$10",
    remaining:"$890",
    target:"$900",
  },

  /** Mosque Construction Projects */
  {
    id:"7", 
    imagePath:"mosque_proj_1.jpeg", 
    projectName: "Butebo Mosque",
    tag: "Mosque Constructions",
    funded:"$450",
    remaining:"$600",
    target:"$1050",
  },


  /**Orphan Projects */
  {
    id:"8", 
    imagePath:"66.jpeg", 
    projectName: "Orphan School Support",
    tag: "Orphans",
    funded:"$30",
    remaining:"$1980",
    target:"$2000",
  },
  {
    id:"9", 
    imagePath:"4.jpg", 
    projectName: "Orphan Feeding Project in Mbale",
    tag: "Orphans",
    funded:"$500",
    remaining:"$400",
    target:"$900",
  },


  /**Noble Quran Donation Projects */
  {
    id:"10", 
    imagePath:"24.jpg", 
    projectName: "Quran Donation Projects",
    tag: "Noble Quran",
    funded:"$9",
    remaining:"$691",
    target:"$700",
  },

  /** Relief Aid Projects */
  {
    id:"11", 
    imagePath:"17.jpg", 
    projectName: "Food Aid in Bulambuli",
    tag: "Relief Aid",
    funded:"$13",
    remaining:"1187",
    target:"$1200",
  },
  {
    id:"12", 
    imagePath:"34.jpg", 
    projectName: "Bumwambu Food Support",
    tag: "Relief Aid",
    funded:"$500",
    remaining:"$400",
    target:"$900",
  },
  {
    id:"13", 
    imagePath:"47.jpg", 
    projectName: "Karamoja Food Aid",
    tag: "Relief Aid",
    funded:"$400",
    remaining:"$600",
    target:"$1000",
  },
  {
    id:"14", 
    imagePath:"49.jpg", 
    projectName: "Fight Hunger in Bukedea District",
    tag: "Relief Aid",
    funded:"$200",
    remaining:"$800",
    target:"$1000",
  },

  /**Qurban Projects */
  {
    id:"15", 
    imagePath:"57.jpg", 
    projectName: "2024 Qurban Project ",
    projectDescription: "The 2024 Qurban Project is a significant initiative aiming to make a meaningful and impactful contribution through Qurban. This project seeks to fulfill a religious obligation while supporting those in need.",
    tag: "Qurban Projects",
    funded:"$0",
    remaining:"$20,000",
    target:"$20,000",
  },
  {
    id:"16", 
    imagePath:"54.jpg", 
    projectName: "March Meat Aid in Manafwa",
    tag: "Qurban Projects",
    funded:"$250",
    remaining:"$850",
    target:"$1010",
  },

  {
    id:"17", 
    imagePath:"null", 
    projectName: "Al-ruhmah 2024 Scholarship Initiative ",
    projectDescription: "The Al-ruhmah 2024 Scholarship Initiative is committed to empowering the future through educational opportunities. This project aims to provide scholarships for deserving individuals, contributing to their academic and personal growth.",
    tag: "Education Projects",
    funded:"$0",
    remaining:"$10,000",
    target:"$10,000",
  },
  {
    id:"18", 
    imagePath:"null", 
    projectName: "Back to School Project",
    tag: "Education Projects",
    funded:"$2",
    remaining:"$898",
    target:"$900",
  },
];

export default cards_data;