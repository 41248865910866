import React from 'react'
import './About_us.css'

function About_us() {
  return (
    <div>
        
    </div>
  )
}

export default About_us